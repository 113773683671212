@charset "UTF-8";

@font-face {
  font-family: 'Roboto';
  src: url('./../fonts/Roboto-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./../fonts/Roboto-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Taviraj';
  src: url('./../fonts/Taviraj-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Taviraj';
  src: url('./../fonts/Taviraj-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Taviraj';
  src: url('./../fonts/Taviraj-Italic.ttf');
  font-style: italic;
}

@font-face {
  font-family: 'Taviraj';
  src: url('./../fonts/Taviraj-BlackItalic.ttf');
  font-weight: bold;
  font-style: italic;
}



@font-face {
  font-family: 'dru';
  src: url('./../fonts/Druk-Cyr-Heavy.otf');
  font-weight: normal;
}

@font-face {
  font-family: 'dru';
  src: url('./../fonts/Druk-Text-Cyr-Super.otf');
  font-weight: bold;
}

$font_taviraj: 'Taviraj';
$font_dru: 'dru';

