@import "preimport";

$layerWindow: 64.000rem;

html, body {
  //font-size: 40px;
  font-size: 2.7777vw;

  @include bpv(1200) {
    font-size: 3.75vw;
  }
  /*
    @include bpv(1200) {
      font-size: 12px;
    }*/
}

body {
  font-family: $font_taviraj;
  background-color: black;
  color: white;
}

.App {
  overflow: hidden;

  ._en {
    display: none;
  }

  .App__content {
    position: relative;
    width: 32.6875rem;
    margin: 0 auto;
  }

  //////// MENU

  .nav {
    position: absolute;
    cursor: pointer;
    right: 0;
    @include flexCenter;
    top: 1rem;
  }

  .nav__language {
    cursor: pointer;
    margin-left: .2rem;
    font-size: 0.59075rem;
    color: white;
    text-transform: uppercase;
    background: none;
    width: 1.1rem;
    font-family: $font_robot;

    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  .nav__option {
    font-family: $font_robot;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  .language-pic {
    display: block;
    @include rect(1.23425rem);
    @include bgCenterContain('language.svg');
  }

  ////// Banner

  .banner {
    @include bgCenterContain('banner.jpg');
    width: 36rem;
    height: 20.25rem;
    flex-shrink: 0;
    position: relative;
    margin: auto;
  }

  .banner__shade {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
    position: absolute;
    bottom: -1px;
    height: 6.15rem;
    width: calc(100% + 2px);
    left: -1px;
  }

  .logo {
    width: 22.275rem;
    height: 10.098rem;
    margin-top: 1rem;
    margin-left: -1.3rem;
  }

  .logo_eng {
    @include bgCenterContain('logo-en.png');
  }

  .logo_it {
    @include bgCenterContain('logo-it.png');
  }

  .banner__texts {
    width: 17rem;
    text-align: center;
    margin-top: -.9rem;
  }

  .banner__compositor {
    text-transform: uppercase;
    font-size: 0.97798rem;
    font-family: $font_robot;
  }

  .banner__compositor-name {
    text-transform: uppercase;
    font-size: 2.2655rem;
    font-weight: bold;
    font-family: $font_dru;
  }

  .banner__compositor-wrap_1 {
    margin-top: .8rem;
  }

  .banner__compositor-wrap {
    height: .8rem;
    @include flexCenter;
  }

  .banner__compositor-subtext {
    font-size: 0.5rem;
    font-weight: bold;
    font-family: $font_taviraj;
    line-height: .9;

    &_2 {
      margin-left: .2rem;
    }
  }

  .banner__partners {
    width: 10.87505rem;
    height: 1.6801rem;
    flex-shrink: 0;
    @include bgCenterContain('banner-partners.svg');
  }

  .banner__partners-wrap {
    @include flexCenter;
    margin-top: .8rem;
  }

  ////// Head

  .header {
    font-family: $font_taviraj;
    font-size: 0.71565rem;
    width: 17.21415rem;
    @include flexCenter;
    padding-top: .89rem;
    font-weight: bold;
  }

  .header__block_left {
    position: relative;
    padding-right: .2rem;

    &::after {
      position: absolute;
      height: 140%;
      border-right: .1rem solid white;
      content: ' ';
      right: 0;
      top: 50%;
      transform: rotate(20deg) translateY(-50%);
    }
  }

  .header__block_right {
    padding-left: 1.1rem;
  }

  .header-right__text_1 {
    display: block;
  }


  //////// ABOUT

  .about {
    @include flexCenter;
    align-items: flex-start;
    padding-bottom: 2rem;
    padding-top: 3rem;
  }

  .about__headline {
    font-family: $font_dru;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 900;
    line-height: 90%; /* 2.35463rem */
  }

  .about__subhead {
    font-family: $font_robot;
    font-size: 0.89283rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.00892rem;
    margin-top: .9rem;
    display: block;
  }

  .about_paragraph {
    font-family: $font_robot;
    font-size: 0.525rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.00525rem;
    margin-top: .7rem;
  }

  .about__quote {
    font-size: 4.27507rem;
    font-family: $font_dru;
    font-style: normal;
    font-weight: 900;
    line-height: 90%; /* 12.82525rem */
    text-transform: uppercase;
    height: 1.5rem;
    margin-top: .5rem;
  }

  .about__author {
    font-family: $font_dru;
    font-size: 0.96698rem;
    font-style: normal;
    font-weight: 900;
    line-height: 90%; /* 2.90092rem */
    text-transform: uppercase;
    margin-top: .5rem;
    display: block;
  }

  .about__button-wrap {
    @include flexCenter;
    position: absolute;
    right: .5rem;
    bottom: 0;
  }

  .about__button {
    background-color: white;
    color: black;
    border-radius: 1rem;
    font-family: $font_robot;
    font-size: 0.88112rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 1rem;
    display: inline-flex;
    padding: 0.33473rem 1.17158rem;
    justify-content: center;
    align-items: center;
    gap: 0.27895rem;
  }

  .about__column_left {
    padding-right: 1rem;
  }

  .about__image {
    width: 12.45rem;
    height: 16.6rem;
    flex-shrink: 0;
    @include bgCenterContain('about.png');
  }


  ////// VIDEO

  .video {
    width: 36rem;
    height: 20.25rem;
    flex-shrink: 0;
    margin: auto;
    @include bgCenterContain('video.png');
  }


  ////// CARTOZ

  .cartoz {
    @include flexCenter;
    align-items: flex-start;
    margin-top: 3.5rem;
  }

  .cartoz__image {
    width: 12.575rem;
    height: 19.675rem;
    @include bgCenterContain('cartoz.png');
  }

  .cartoz__column_left {
    flex-shrink: 0;
  }

  .cartoz__column_right {
    padding-left: 1.9rem;
  }

  .cartoz__name {
    font-family: $font_dru;
    font-size: 2.61615rem;
    font-style: normal;
    font-weight: 900;
    line-height: 90%; /* 2.35452rem */
  }

  .cartoz__paragraph {
    font-family: $font_robot;
    font-size: 0.525rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.00525rem;
    display: block;
    margin-top: .7rem;


    &:nth-child(1) {
      margin-top: 1rem;
    }

    li {
      list-style-type: disc;
      margin-left: .6rem;
    }
  }

  .cartoz__music {
    margin-top: 1rem;
    display: inline-flex;
  }

  .cartoz-music__item {
    width: 5.68663rem;
    height: 1.92115rem;
    flex-shrink: 0;
    display: block;
  }

  .cartoz__spotify {
    @include bgCenterContain('spotify.svg');
  }

  .cartoz__apple {
    margin-left: .5rem;
    @include bgCenterContain('apple.svg');
  }


  ////// FOOTER

  .footer {
    margin-top: 3rem;
    border-top: 2px solid white;
    padding-bottom: 2rem;
  }

  .footer__logo_1 {
    margin-top: 1rem;
    width: 19.625rem;
    height: 1.93358rem;
    flex-shrink: 0;

    @include bgCenterContain('logos-bottom.svg');
  }

  @include bpt {
    .App__content {
      width: 24rem;
    }

    .header {
      width: 7rem;
      flex-direction: column;
      font-weight: normal;
    }

    .banner {
      width: 100%;
      height: 47.5rem;
      background-image: url("./../img/banner-m.png");
      background-position: top center;
    }

    .header__block_left {
      width: 100%;

      &::after {
        border-right: none;
        border-bottom: 1px solid white;
        width: 100%;
        height: 0;
        top: 100%;
        margin-top: .5rem;
        transform: none;
      }
    }

    .header-left__text-1 {
      font-size: .82rem;
    }

    .header-left__text-2 {
      font-size: 2.3545rem;
      font-style: italic;
      font-weight: bold;
      font-family: $font_taviraj;
    }

    .header-left__text-3 {
      font-family: $font_taviraj;
      line-height: 1;
      display: block;
      font-size: 0.82625rem;
      font-style: normal;
      font-weight: 400;
    }

    .header-left__text-4 {
      font-family: $font_taviraj;
      display: block;
      font-size: 1.49183rem;
      font-style: italic;
      font-weight: 700;
      line-height: 90%; /* 0.74358rem */
      margin-top: .3rem;
    }

    .header__block_right {
      padding-left: 0;
      width: 100%;
      margin-top: 1.4rem;
    }

    .header-right__text {
      display: block;
      font-family: $font_taviraj;
      font-size: 0.86067rem;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 0.77458rem */
    }

    .logo {
      width: 27rem;
      height: 12.61775rem;
      margin-top: -1rem;
      margin-left: 50%;
      transform: translateX(-50%);
    }

    .logo_eng {
      width: 25rem;
    }

    .banner__texts {
      width: 100%;
      margin-top: -1.3rem;
    }

    .banner__compositor {
      font-size: 1.12475rem;
      letter-spacing: 0.1125rem;
    }

    .banner__compositor-wrap {
      flex-direction: column;
      height: auto;
    }

    .banner__compositor-subtext {
      font-size: 0.832rem;
      line-height: 1;
    }

    .banner__shade {
      height: 13.5rem;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
    }

    .banner__partners-wrap {
      margin-top: 11.5rem;
    }

    .banner__compositor-wrap_1 {
      margin-top: 1.5rem;
    }

    .about {
      flex-direction: column;
    }

    .about__headline {
      font-size: 4rem;
      width: 18.6rem;
      margin-top: 4.5rem;
    }

    .about__subhead {
      font-size: 1.94rem;
    }

    .about_paragraph {
      font-size: 1.25rem;
      margin-top: 1.75rem;
    }

    .about__quote {
      font-size: 9rem;
      height: 2.8rem;
    }

    .about__author {
      font-size: 2.06125rem;
    }

    .about__image {
      width: 100%;
      height: 32rem;
      margin-top: 1.75rem;
    }

    .about__column_left {
      padding-right: 0;
    }

    .about__button {
      display: inline-flex;
      padding: 0.51958rem 1.8185rem;
      justify-content: center;
      align-items: center;
      gap: 0.433rem;
      font-size: 1.36767rem;
      border-radius: 3.29067rem;
      font-family: $font_robot;
      margin-top: 2rem;
    }

    .video {
      margin-top: 1.33rem;
    }

    .cartoz {
      flex-direction: column;
    }

    .cartoz__column {
      width: 100%;
    }

    .cartoz__column_right {
      padding-left: 0;
    }

    .cartoz__name {
      font-size: 4rem;
    }

    .cartoz__image {
      margin-top: 1.75rem;
      width: 100%;
      height: 37.2rem;
      flex-shrink: 0;
    }

    .cartoz__paragraph {
      font-size: 1.25rem;
    }

    .cartoz__spotify {
      width: 11.80408rem;
      height: 3.8rem;
    }

    .cartoz__apple {
      width: 11.80408rem;
      height: 3.8rem;
      margin-left: auto;
    }

    .cartoz__paragraph li {
      margin-left: 1.6rem;
    }

    .video {
      width: 100vw;
      height: 15rem;
    }

    .language-pic {
      @include rect(2.24rem);
    }

    .nav {
      top: 2rem;
    }

    .nav__language {
      font-size: 1.96rem;
      width: 4rem;
    }

    .about__button-wrap {
      position: relative;
    }

    .cartoz__music {
      margin-top: 0;
      display: flex;
    }

  }

  &.App_eng {
    ._en {
      display: block;
    }

    @include bpt {
      .header {
        padding-top: 2rem;
      }

      .header-left__text-2 {
        display: inline;
        font-style: normal;
        font-weight: 400;
        font-size: 0.82625rem;
      }

      .header-left__text-3 {
        font-size: 2.3545rem;
        font-style: italic;
        font-weight: 700;
      }

      .header-left__text-4 {
        font-size: 1.49183rem;
      }

      .logo {
      }

      .banner__partners-wrap {
        margin-top: 12.65rem;
      }

      .cartoz__music {
        margin-top: 2rem;
      }
    }
  }

  ._desktop {
    @include bpt {
      display: none;
    }
  }

  ._mobile {
    @include bpd {
      display: none;
    }
  }
}