@mixin absBlock() {
  content: '';
  position: absolute;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin absFull() {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@mixin setRemSize($maxFontSize: 23, $minFontSize: 12, $maxScreenWidth: 650, $minScreenWidth: 320, $steps: 100) {
  @for $i from 0 to $steps {
    $screenSize: $maxScreenWidth - $i * ($maxScreenWidth - $minScreenWidth)/$steps;
    @include bpv($screenSize) {
      $fontSize: $maxFontSize - $i * ($maxFontSize - $minFontSize) / $steps;
      font-size: #{$fontSize}px;
    }
  }
}

@mixin btn() {
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      opacity: .8;
    }
  }

  @media (hover: none) {
    &:hover {
      opacity: 1;
    }
  }

  @media (any-hover: none), (any-pointer: coarse) {
    &:hover {
      opacity: 1;
    }
  }
}

@mixin flexCenter() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin rect($size) {
  width: $size;
  height: $size;
}

@mixin bgCenterContain($bgImage:null) {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  @if $bgImage {
    background-image: url('./../img/' + $bgImage);
  }
}

$font_intro: "intro_",SansSerif;
$font_robot: Roboto, sans-serif;

@function is-direction($value) {
  $is-keyword: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-keyword or $is-angle;
}
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
  }

  $conversion-map: (
          to top          : bottom,
          to top right    : bottom left,
          to right top    : left bottom,
          to right        : left,
          to bottom right : top left,
          to right bottom : left top,
          to bottom       : top,
          to bottom left  : top right,
          to left bottom  : right top,
          to left         : right,
          to left top     : right bottom,
          to top left     : bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - $value;
}

@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin fonts(){
  @font-face {
    font-family: 'intro_';
    src: url("https://cdn.friday.ru/friday/font/intro_0-webfont.eot");
    src: url("https://cdn.friday.ru/friday/font/intro_0-webfont.eot?#iefix") format("embedded-opentype"),url("https://cdn.friday.ru/friday/font/intro_0-webfont.woff") format("woff"),url("https://cdn.friday.ru/friday/font/intro_0-webfont.ttf") format("truetype"),url("https://cdn.friday.ru/friday/font/intro_0-webfont.svg#intro") format("svg");
    font-weight: normal;
    font-style: normal
  }
  @font-face {
    font-family: 'icomoon_sp';
    src:  url('https://cdn.friday.ru/friday/fonts/icomoon-sp.eot?3p215l');
    src:  url('https://cdn.friday.ru/friday/fonts/icomoon-sp.eot?3p215l#iefix') format('embedded-opentype'),
    url('https://cdn.friday.ru/friday/fonts/icomoon-sp.ttf?3p215l') format('truetype'),
    url('https://cdn.friday.ru/friday/fonts/icomoon-sp.woff?3p215l') format('woff'),
    url('https://cdn.friday.ru/friday/fonts/icomoon-sp.svg?3p215l#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon_sp',sans-serif !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .icon-fb:before {
    // content: "\e900";
  }
  .icon-heart_icon:before {
    content: "\e901";
  }
  .icon-ok:before {
    content: "\e902";
  }
  .icon-vk:before {
    content: "\e903";
  }
}
@mixin text3d($steps,$color){
  $s:'';
  $px:1;
  @for $i from 1 through $steps {
    $s: $s+ $px+px $px+px $color;
    @if $i!=$steps{
      $s: $s+ ', ';
    }
    @else {
      $s: $s+ '';
    }
    $px: $px+1;
  }
  text-shadow: unquote($s);
}
@mixin skew($deg){
  -moz-transform:  skewY($deg+deg);
  -ms-transform:  skewY($deg+deg);
  -o-transform:  skewY($deg+deg);
  -webkit-transform: skewY($deg+deg);
  transform: skewY($deg+deg);
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}

@mixin bpd() {
  @media (min-width: 1025px) { @content; }
}
@mixin bpt() {
  @media (max-width: 1024px) { @content; }
}
@mixin bpb() {
  @media (max-width: 700px) { @content; }
}
@mixin bplt() {
  @media (max-width: 768px) { @content; }
}
@mixin bpds{
  @media (min-width: 1025px) and  (max-height: 780px) {@content;}
}
@mixin bpts{
  @media (max-width: 1024px) and (min-width: 650px) and  (max-height: 780px) {@content;}
}
@mixin b2k() {
  @media (min-width: 1995px) and  (max-width: 2049px) {@content;}
}
@mixin b4k() {
  @media (min-width: 3800px) and  (max-width: 4000px) {@content;}
}
@mixin bpmb() {
  @media (max-width: 650px) and (min-height: 600px) {@content;}
}
@mixin bpm() {
  @media (max-width: 778px) {@content;}
}
@mixin bpv($width) {
  @media (max-width: $width+px) { @content; }
}
@mixin dn{
  /*display: none;*/
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}


@mixin transform2($name,$value,$name2,$value2){
  -webkit-transform:$name+'('+$value+')'$name2+'('+$value2+')';
  -moz-transform:$name+'('+$value+')'$name2+'('+$value2+')';
  -ms-transform:$name+'('+$value+')'$name2+'('+$value2+')';
  -o-transform:$name+'('+$value+')'$name2+'('+$value2+')';
  transform:$name+'('+$value+')'$name2+'('+$value2+')';
}

@mixin transform($name,$value){
  -webkit-transform:$name+'('+$value+')';
  -moz-transform:$name+'('+$value+')';
  -ms-transform:$name+'('+$value+')';
  -o-transform:$name+'('+$value+')';
  transform:$name+'('+$value+')';
}
@mixin transition($name,$time,$speed:linear){
  -webkit-transition:$name $time $speed;
  -moz-transition:$name $time $speed;
  -ms-transition:$name $time $speed;
  -o-transition:$name $time $speed;
  transition:$name $time $speed;
}
@mixin scale($size){
  -webkit-transform:scale($size);
  -moz-transform:scale($size);
  -ms-transform:scale($size);
  -o-transform:scale($size);
  transform:scale($size);
}
@mixin animation($name,$time,$speed:linear,$count:infinite){
    -webkit-animation: $name $time $speed $count;
    -moz-animation: $name $time $speed $count;
    -ms-animation: $name $time $speed $count;
    -o-animation: $name $time $speed $count;
    animation: $name $time $speed $count;
}
@mixin absoluteFull(){
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
@mixin pictureSizedWrap($heightRatio:131.9559%,$width:100%){
  height: 0;
  width: $width;
  padding-top: $heightRatio;
  position: relative;
  &>div{
    @include absoluteFull;
    background: no-repeat center;
    background-size: cover;
  }
}